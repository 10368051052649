import VueRouter from "vue-router";
import { store } from "./store";

import Login from "src/views/Login";
import BookmarkEdit from "src/views/bookmarks/edit";
import BookmarkForm from "src/views/bookmarks/form";
import BookmarkIndex from "src/views/bookmarks/index";

import TemplateEdit from "src/views/templates/edit";
import TemplateForm from "src/views/templates/form";
import TemplateIndex from "src/views/templates/index";

import AudienceServicesIndex from "src/views/audience/AudienceServicesIndex";

import CampaignIndex from "src/views/campaigns/index";
import CampaignCreateFromBookmark from "src/views/campaigns/create-from-bookmark/form";
import CampaignCreateFromTemplate from "src/views/campaigns/create-from-template/form";
import CampaignCreateFromPing from "src/views/campaigns/create-from-ping/form";
import CampaignCreateFromScratch from "src/views/campaigns/create-from-scratch/form";
import CampaignEditFromPing from "src/views/campaigns/create-from-ping/edit";
import CampaignEditFromScratch from "src/views/campaigns/create-from-scratch/edit";
import CampaignManager from "src/views/campaigns-manager/index";

import SegmentationToolIndex from "src/views/segmentation-tool/index";
import SegmentationToolForm from "src/views/segmentation-tool/form";
import SegmentationToolEdit from "src/views/segmentation-tool/edit";

import SegmentationIndex from "src/views/segmentation/index";
import SegmentationForm from "src/views/segmentation/form";

import DashboardIndex from "src/views/dashboard/index";
import DashboardLayout from "src/custom-components/Dashboard/Layout/DashboardLayout.vue";
import DashboardLive from "src/views/dashboard/live";

import DebugIndex from "src/views/debug/index";

import NotFoundPage from "src/views/NotFoundPage";

import PerformanceCampaignEdit from "src/views/performance-campaigns/edit";
import PerformanceCampaignForm from "src/views/performance-campaigns/form";
import PerformanceCampaignIndex from "src/views/performance-campaigns/index";
import PerformanceCampaignView from "src/views/performance-campaigns/view";
import CampaignPivot from "src/views/campaigns/pivot";
import ProductAssignationEdit from "src/views/product-assignation/edit";
import ProductAssignationForm from "src/views/product-assignation/form";
import ProductAssignationIndex from "src/views/product-assignation/index";

import SmppGatewayIndex from "src/views/smpp-gateway/index";
import SmscTesterIndex from "src/views/smsc-tester/index";

import AgentHome from "src/views/agent/agent-home";
import AgentForm from "src/views/agent/agent-form";

import CrmIndex from "src/views/crm/CrmIndex";

import AccountConfigurationIndex from "src/views/account-configuration/index";
// import CrmCampaigns from 'src/views/crm/CrmCampaigns';
// import CrmEvents from 'src/views/crm/CrmEvents';
import CustomerServiceIndex from "src/views/customer-service/CustomerServiceIndex";

import StatusConnection from "src/views/connection/Status";

import MyProfileIndex from "src/views/my-profile/MyProfileIndex";

import ApiEventCreateFromBookmark from "src/views/events/api-events/create-from-bookmark/form";
import ApiEventCreateFromScratch from "src/views/events/api-events/create-from-scratch/form";
import ApiEventEdit from "src/views/events/api-events/create-from-bookmark/edit";
import ApiEventIndex from "src/views/events/api-events/index";

export const COMMON_ROUTES = {
  DASHBOARD: "Dashboard",
  LOGIN: "Login",
};

const commonChildren = [
  {
    path: "second-action/:id",
    component: TemplateIndex,
    // component: BookmarkIndex,
    props: true,
    meta: {
      showModal: true,
    },
  },
];

const routes = [
  {
    name: COMMON_ROUTES.LOGIN,
    path: "/login",
    component: Login,
  },
  {
    name: COMMON_ROUTES.DASHBOARD,
    path: "/",
    component: DashboardLayout,
    redirect: "/dashboard/index",
  },
  {
    path: "/campaigns",
    component: DashboardLayout,
    redirect: "/campaigns/planning",
    children: [
      {
        path: "create-from-ping/add",
        name: "CampaignCreateFromPing",
        component: CampaignCreateFromPing,
      },

      {
        path: "create-from-ping/edit/:id",
        name: "CampaignEditFromPing",
        component: CampaignEditFromPing,
      },
      {
        path: "ota/create",
        name: "CampaignCreateFromOta",
        component: CampaignCreateFromPing,
      },
      {
        path: "ota/edit/:id",
        name: "CampaignEditOta",
        component: CampaignEditFromPing,
      },
      {
        path: "create",
        name: "CampaignCreate",
        component: CampaignCreateFromScratch,
        children: commonChildren,
      },
      {
        path: "create/:bookmarkId",
        name: "CampaignCreateTemplate",
        component: CampaignCreateFromScratch,
        children: commonChildren,
      },
      {
        path: "edit/:id",
        name: "CampaignEdit",
        component: CampaignEditFromScratch,
        children: commonChildren,
      },

      {
        // TODO: Borrar despues de cambiar el resto de las rutas
        path: "new/from-template-:bookmarkId",
        name: "CampaignCreateFromTemplate",
        component: CampaignCreateFromTemplate,
        children: commonChildren,
      },
      {
        // TODO: Borrar despues de cambiar el resto de las rutas
        path: "new/from-bookmark-:bookmarkId",
        name: "CampaignCreateFromBookmark",
        component: CampaignCreateFromBookmark,
        children: commonChildren,
      },

      {
        // TODO: Borrar despues de cambiar el resto de las rutas
        path: "create-from-bookmark/edit/:id",
        name: "EditCampaignFromBookmark",
        component: CampaignEditFromScratch,
        children: commonChildren,
      },
      {
        // TODO: Borrar despues de cambiar el resto de las rutas
        path: "create-from-scratch/add",
        name: "CampaignCreateFromScratch",
        component: CampaignCreateFromScratch,
        children: commonChildren,
      },
      {
        // TODO: Borrar despues de cambiar el resto de las rutas
        path: "create-from-scratch/edit/:id",
        name: "CampaignEditFromScratch",
        component: CampaignEditFromScratch,
        children: commonChildren,
      },
      {
        path: "pivot",
        name: "CampaignPivot",
        component: CampaignPivot,
      },
      {
        path: "planning",
        name: "IndexCampaignFromScratch",
        component: CampaignIndex,
      },
    ],
  },
  {
    path: "/api-events",
    component: DashboardLayout,
    redirect: "/campaigns/planning",
    children: [
      {
        path: "create",
        name: "ApiEventCreate",
        component: ApiEventCreateFromBookmark,
      },
      {
        path: "create/:bookmarkId",
        name: "ApiEventCreateTemplate",
        component: ApiEventCreateFromBookmark,
      },
      {
        path: "edit/:id",
        name: "ApiEventEdit",
        component: ApiEventEdit,
      },

      // TODO: Borrar despues de cambiar el resto de las rutas
      {
        path: "create-from-bookmark/add",
        name: "ApiEventCreateFromBookmark",
        component: ApiEventCreateFromBookmark,
      },
      // TODO: Borrar despues de cambiar el resto de las rutas
      {
        path: "create-from-scratch/add",
        name: "ApiEventCreateFromScratch",
        component: ApiEventCreateFromScratch,
      },
      // TODO: Borrar despues de cambiar el resto de las rutas
      {
        path: "planning",
        name: "IndexApiEvents",
        component: ApiEventIndex,
      },
      // TODO: Borrar despues de cambiar el resto de las rutas
      {
        path: "create-from-bookmark/edit/:id",
        name: "EditApiEventFromBookmark",
        component: ApiEventEdit,
      },
    ],
  },
  {
    path: "/campaigns-manager",
    component: DashboardLayout,
    redirect: "/campaigns-manager/index",
    children: [
      {
        path: "index",
        name: "IndexCampaignManager",
        component: CampaignManager,
      },
    ],
  },
  {
    path: "/segmentation-tool",
    component: DashboardLayout,
    redirect: "/segmentation-tool",
    children: [
      {
        path: "index",
        name: "IndexSegmentationTool",
        component: SegmentationToolIndex,
      },
      {
        path: "add",
        name: "FormSegmentationTool",
        component: SegmentationToolForm,
      },
      {
        path: "edit/:id",
        name: "EditSegmentationTool",
        component: SegmentationToolEdit,
      },
    ],
  },
  {
    path: "/dashboard",
    component: DashboardLayout,
    redirect: "/dashboard/index",
    children: [
      {
        path: "index",
        name: "DashboardIndex",
        component: DashboardIndex,
      },
      {
        path: "live",
        name: "DashboardLive",
        component: DashboardLive,
      },
    ],
  },

  {
    path: "/debug",
    component: DashboardLayout,
    redirect: "/debug/index",
    children: [
      {
        path: "index",
        name: "DebugIndex",
        component: DebugIndex,
      },
    ],
  },

  {
    path: "/bookmarks2",
    component: DashboardLayout,
    redirect: "/bookmarks2/index",
    children: [
      {
        path: "index",
        name: "ListBookmarks2",
        component: BookmarkIndex,
      },
      {
        path: "add",
        name: "AddBookmark2",
        component: BookmarkForm,
      },
      {
        path: "edit/:id",
        name: "EditBookmark2",
        component: BookmarkEdit,
      },
    ],
  },
  {
    path: "/bookmarks",
    component: DashboardLayout,
    redirect: "/bookmarks/index",
    children: [
      {
        path: "index",
        name: "ListBookmarks",
        component: TemplateIndex,
      },
      {
        path: "add",
        name: "AddBookmark",
        component: TemplateForm,
      },
      {
        path: "edit/:id",
        name: "EditBookmark",
        component: TemplateEdit,
      },
    ],
  },
  {
    path: "/audience",
    component: DashboardLayout,
    redirect: "/audience/services",
    children: [
      {
        path: "services",
        name: "AudienceServicesIndex",
        component: AudienceServicesIndex,
      },
    ],
  },
  {
    path: "/performance-campaigns",
    component: DashboardLayout,
    redirect: "/performance-campaigns/index",
    children: [
      {
        path: "index",
        name: "ListPerformanceCampaigns",
        component: PerformanceCampaignIndex,
      },
      {
        path: "add",
        name: "AddPerformanceCampaign",
        component: PerformanceCampaignForm,
      },
      {
        path: "edit/:id",
        name: "EditPerformanceCampaign",
        component: PerformanceCampaignEdit,
      },
      {
        path: "view/:id",
        name: "ViewPerformanceCampaign",
        component: PerformanceCampaignView,
      },
    ],
  },
  {
    path: "/product-assignation",
    component: DashboardLayout,
    redirect: "/product-assignation/index",
    children: [
      {
        path: "index",
        name: "ProductAssignationIndex",
        component: ProductAssignationIndex,
      },
      {
        path: "add",
        name: "ProductAssignationAdd",
        component: ProductAssignationForm,
      },
      {
        path: "edit/:id",
        name: "ProductAssignationEdit",
        component: ProductAssignationEdit,
      },
    ],
  },
  {
    path: "/smpp-gateway",
    component: DashboardLayout,
    redirect: "/smpp-gateway/index",
    children: [
      {
        path: "index",
        name: "SmppGatewayIndex",
        component: SmppGatewayIndex,
      },
    ],
  },
  {
    path: "/rcs",
    component: DashboardLayout,
    redirect: "/rcs/agents",
    children: [
      {
        path: "agents",
        name: "AgentHome",
        component: AgentHome,
      },
      {
        path: "add",
        name: "AddAgent",
        component: AgentForm,
      },
      {
        path: "edit/:id",
        name: "EditAgent",
        component: AgentForm,
      },
    ],
  },
  {
    path: "/smsc-tester",
    component: DashboardLayout,
    redirect: "/smsc-tester/index",
    children: [
      {
        path: "index",
        name: "SmscTesterIndex",
        component: SmscTesterIndex,
      },
    ],
  },
  {
    path: "/account-configuration",
    component: DashboardLayout,
    redirect: "/account-configuration/index",
    children: [
      {
        path: "index",
        name: "AccountConfigurationIndex",
        component: AccountConfigurationIndex,
      },
    ],
  },
  {
    path: "/connection",
    component: DashboardLayout,
    redirect: "/connection/status",
    children: [
      {
        path: "status",
        name: "StatusConnection",
        component: StatusConnection,
      },
    ],
  },
  {
    path: "/crm",
    component: DashboardLayout,
    redirect: "/crm/index",
    children: [
      {
        path: "index",
        name: "CrmIndex",
        component: CrmIndex,
      },
    ],
  },
  {
    path: "/customer-service",
    component: DashboardLayout,
    redirect: "/customer-service/index",
    children: [
      {
        path: "index",
        name: "CustomerServiceIndex.vue",
        component: CustomerServiceIndex,
      },
    ],
  },
  {
    path: "/my-profile",
    component: DashboardLayout,
    redirect: "/my-profile/index",
    children: [
      {
        path: "/",
        name: "MyProfileIndex",
        component: MyProfileIndex,
      },
    ],
  },
  {
    path: "/segmentation",
    component: DashboardLayout,
    redirect: "/segmentation/index",
    children: [
      {
        path: "/",
        name: "SegmentationIndex",
        component: SegmentationIndex,
      },
      /* {
        path: "create",
        name: "SegmentationCreate",
        component: SegmentationForm,
      },
      {
        path: "create/:id",
        name: "SegmentationCopy",
        component: SegmentationForm,
      }, */
      {
        path: "info/:id",
        name: "SegmentationInfo",
        component: SegmentationForm,
      },
      {
        path: "edit/:id",
        name: "SegmentationEdit",
        component: SegmentationForm,
      },
    ],
  },
  
  { path: "*", component: NotFoundPage },
];

const router = new VueRouter({
  routes, // short for routes: routes
  linkActiveClass: "active",
});

router.beforeEach((to, from, next) => {
  console.log("Chequeando ruta: ", { from, to, globalState: store.state });
  if (store.state.isLoadingAuth) {
    next();
  } else if (store.state.user == null && to.name !== "Login") {
    // redirect the user to the login page
    console.debug("Not authenticated. Redirecting to login");
    next({ name: "Login" });
  } else if (store.state.user && to.name === "Login") {
    next({ name: "Dashboard" });
  } else {
    next();
  }
});

export default router;
