<template>
  <div class="row">
    <div class="col-md-12">
      <div class="form-group has-label">
        <label>{{ $t("Scenario / Campaign flow") }}</label>
        <el-select
          size="large"
          v-validate="noValidation ? null : 'required'"
          name="scenario"
          :clearable="clearable"
          :value="local"
          @input="$emit('input', $event)"
          :disabled="disabled"
          @change="notifyChange"
        >
          <el-option
            v-for="option in getScenarioByChannel(channel, scenarios)"
            :value="option.id"
            :label="$t(option.alias ? option.alias : option.scenario.name)"
            :key="option.name"
          />
        </el-select>
      </div>

      <div
        v-show="errors.has('scenario')"
        class="text-danger invalid-feedback"
        style="display: block"
      >
        {{ errors.first("scenario") }}
      </div>
    </div>
  </div>
</template>

<script>
import api from "src/api";
import { Select, Option } from "element-ui";
import { EventBus } from "src/eventBus";

export default {
  inject: ["getError", "parentValidator"],
  props: {
    value: Number,
    disabled: Boolean,
    channel: Number,
    filterBy: {
      type: Function,
      required: false,
    },
    noValidation: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
  },

  async created() {
    this.$validator = this.parentValidator;
    this.getScenarioTypes();
  },

  computed: {
    local() {
      return this.value ? this.value : null;
    },
  },

  watch: {
    filterBy: {
      immediate: true,
      handler() {
        return this.getScenarioTypes();
      },
    },
  },

  data() {
    return {
      scenarios: [],
      filteredScenarios: [],
    };
  },

  methods: {
    notifyChange($event) {
      const entity = this.filteredScenarios.find((x) => x.id == $event);
      let creativitiesNotAllowed = false;
      if (
        entity.scenario.name === "SatPush Multimedia" ||
        entity.scenario.name === "Custom Click to SMS"
      ) {
        creativitiesNotAllowed = true;
      }
      this.$emit("change", creativitiesNotAllowed);
    },

    async getScenarioTypes() {
      const scenarios = await api.getScenarioTypes();
      const commonFilter = (item) => {
        return true;
      };

      const yes =
        typeof this.filterBy !== "undefined" && this.filterBy !== null;
      const filter = yes ? this.filterBy : commonFilter;
      this.scenarios = scenarios.data.filter((scenario) =>
        filter(scenario)
      );
    },

    getScenarioByChannel(channelId, accountScenarios) {
      const filteredScenarios = accountScenarios.filter(
        (ast) => ast.scenario.channel_id == channelId
      );

      if (filteredScenarios.length === 1) {
        this.$emit("input", filteredScenarios[0].id);
      }

      this.filteredScenarios = filteredScenarios;
      return filteredScenarios;
    },
  },
};
</script>

<style>
</style>
