import Vue, { createApp } from "vue";
import VueRouter from "vue-router";

//import "./pollyfills";
import axios from "axios";
import { sync } from "vuex-router-sync";

import VueNotify from "vue-notifyjs";
import VModal from "vue-js-modal";
import VeeValidate from "vee-validate";
import locale from "element-ui/lib/locale";
import { abilitiesPlugin } from "@casl/vue";
//import VueRx from "vue-rx";
import VueAxios from "vue-axios";
import GlobalComponents from "./globalComponents";
import GlobalDirectives from "./globalDirectives";
import router from "./router";
import { AuthPlugin } from "./auth";
import FlagIcon from "vue-flag-icon";
import SideBar from "./components/UIComponents/SidebarPlugin";

import { ability, store } from "src/store";
import { VeeMessages } from "src/messages";
import { i18n } from "./i18n";
import Siema from "vue2-siema";
import * as vClickOutside from "v-click-outside-x";
import App from "./App.vue";


// import 'element-ui/lib/theme-chalk/index.css'
// import 'element-ui/lib/theme-chalk/icon.css'
import "./assets/css/style.css";
import "./assets/sass/app.scss";

Vue.use(VueAxios, axios);
Vue.use(AuthPlugin, { store, router });
//Vue.use(VueRx);
Vue.use(VueRouter);
Vue.use(GlobalDirectives);
Vue.use(GlobalComponents);
Vue.use(VueNotify);
Vue.use(Siema);
Vue.use(abilitiesPlugin, ability);
Vue.use(VModal);

Vue.use(FlagIcon);
Vue.use(SideBar);

Vue.use(VeeValidate, {
  locale: "en",
  dictionary: VeeMessages.dictionary,
});

Vue.use(vClickOutside);

// Filter for format number
// 1333 | 1,333
// 6557.65 | 6,557.65
Vue.filter("formatNumber", function (stringNumber) {
  if (isNaN(stringNumber) || stringNumber === null) return stringNumber;

  let value = stringNumber;

  if (typeof value !== "string") value = stringNumber % 1 === 0 ? stringNumber.toString() : stringNumber.toFixed(2);

  return value.replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
});

// Filter for format number as percentage
// 0.0593 | 1,333
Vue.filter("formatPercentage", function (value, decimals) {
  if (!value) value = 0;
  if (!decimals) decimals = 0;

  value = value * 100;
  return (Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals)).toString().replace(".", ",") + "%";
});

Vue.filter("formatPercentageExport", function (value, decimals) {
  if (!value) value = 0;
  if (!decimals) decimals = 0;

  value = value * 100;
  return (Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals)).toString().replace(".", ",");
});

// Filter for format size
Vue.filter("formatSize", function (size) {
  if (size > 1024 * 1024 * 1024 * 1024) {
    return (size / 1024 / 1024 / 1024 / 1024).toFixed(2) + " TB";
  } else if (size > 1024 * 1024 * 1024) {
    return (size / 1024 / 1024 / 1024).toFixed(2) + " GB";
  } else if (size > 1024 * 1024) {
    return (size / 1024 / 1024).toFixed(2) + " MB";
  } else if (size > 1024) {
    return (size / 1024).toFixed(2) + " KB";
  }
  return size.toString() + " B";
});

Vue.filter("capitalize", function (value) {
  if (!value) return "";
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
});

Vue.filter("setChannelName", function (channel_id) {
  if (!channel_id) return channel_id;
  const channels = {
    1: "S@T Push",
    2: "SMS",
    3: "Flash SMS",
    4: "InteracTIM",
    5: "Over The Air",
    6: "RCS",
    7: "GBM",
    8: "WhatsApp Api Cloud",
    9: "Facebook",
    10: "WhatsApp BSP",
  };
  return channels[channel_id] ? channels[channel_id] : channel_id;
});

Vue.config.silent = true;

sync(store, router);

import langEn from "element-ui/lib/locale/lang/en";
import langEs from "element-ui/lib/locale/lang/es";

const langStr = store.getters.appUserLanguage;

let lang;
switch (langStr) {
  case "es-ES":
    lang = langEs;
    break;
  case "en-US":
    lang = langEn;
    break;
  default:
    console.log("Language unknown. Defaulting to english.");
    lang = langEn;
}
locale.use(lang);

startApp()

function startApp() {
  const app = new Vue({
    el: "#app",
    render: h => h(App),
    i18n,
    store,
    router
  });
}

//app.use(AuthPlugin, { store });
